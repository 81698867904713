<template>
  <div class="content">
    <div class="content-video">
      <video
        controls="true" controlslist="nodownload" preload="none" 
        :src="`http://114.116.236.37:9001/${curriculumInfo.curriculumVideo}`"
        height="200px"
        width="100%"
      ></video>
    </div>
    <div class="content-item">
      <span class="item-browse">{{ curriculumInfo.curriculumViews }}已浏览</span>
      <span class="van-ellipsis item-title">{{ curriculumInfo.curriculumTitle }}</span>
    </div>
    <div class="boeder-item"></div>
    <span class="teach-title">授课老师</span>
    <van-row class="teach-content">
      <van-col span="6">
        <van-image round :src="require(`@/assets/image/an.png`)" class="teach-portrait" />
      </van-col>
      <van-col span="15" class="teach-item">
        <span class="teach-name">{{ curriculumInfo.curriculumAuthor }}</span>
        <p class="teach-level">老师</p>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { GET, POST } from "@/common/request";
const api = {
  querySrCurriculumInfoById: "/xkcshowApi/show/querySrCurriculumInfoById.htm",
  // 新增学习记录
  addSrRecord: "/xkcshowApi/show/addSrRecord.htm",
  // 新增浏览量
  updateCountParam: "/xkcwebApi/srCurriculum/updateCountParam.htm"
};
// import { POST } from '@/common/request';
// const api = {
//   querySrCurriculumInfoById: '/xkcshowApi/show/querySrCurriculumInfoById.htm',
//   wxLogin: '/xkcshowApi/show/wxLogin2.htm'
// };
export default {
  data() {
    return {
      value: "",
      advertisementList: [],
      curriculumInfo: {},
      isLoading: false
    };
  },
  mounted() {
    // this.getRefreshToken()
    // console.log(this.$route.query.id)
    this.getCurriculumInfo(this.$route.query.id);
  },
  methods: {
    getCurriculumInfo(id) {
      GET(api.querySrCurriculumInfoById, { id })
        .then(res => {
          // debugger
          if (res.code === 200) {
            this.curriculumInfo = res.data;
            this.postAddSrRecord(res.data);
            this.postUpdateCountParam(res.data)
            // debugger
            // this.advertisementList = res.data
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    postUpdateCountParam(val){
      POST(api.updateCountParam, { id:val.id })
        // .then(res => {
        //   // debugger
        //   if (res.code === 200) {
        //     this.curriculumInfo = res.data;
        //     this.postAddSrRecord(res.data);
        //     // debugger
        //     // this.advertisementList = res.data
        //   }
        // })
        .catch(error => {
          console.log(error);
        });
    },
    postAddSrRecord(val) {
      POST(api.addSrRecord, {
        curriculumId: val.id,
        moveUserId: JSON.parse(localStorage.getItem("wechatUserInfo")).value.userId
      })
        // .then(res => {
        //   if (res.code === 200 && res.data.length !== 0) {
        //     // console.log(res);
        //   }
        // })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.content-item {
  height: 30px;
  display: flex;
  line-height: 30px;
  justify-content: space-between;
  margin: 15px;
  .item-browse {
    width: 25%;
    height: 25px;
    line-height: 25px;
    // margin-top: 10px;
    border-radius: 15px;
    background: #9f5cea;
    font-size: 12px;
    color: #fff;
  }
  .item-title {
    width: 60%;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
  }
}
.boeder-item {
  height: 10px;
  background: #f7f7f7;
}
.teach-title {
  display: flex;
  justify-content: start;
  margin: 20px;
  font-size: 18px;
  color: #333333;
  font-weight: 700;
}
.teach-content {
  width: 50%;
  height: 60px;
  background: #f7fafc;
  margin-left: 20px;
  border-radius: 10px;
  .teach-portrait {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 5px 10px;
  }
}
.teach-item {
  // display: flex;
  // height: 50%;
  // width: 90%;
  margin: 15px 0 0 15px;
  .teach-name {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }
  .teach-level {
    font-size: 10px;
    color: #666666;
    margin-top: 10px;
  }
}
</style>